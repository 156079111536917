var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"transparent",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"transparent",attrs:{"items":_vm.orders,"headers":_vm.headers,"mobile-breakpoint":"0","sort-by":"createdDate","sort-desc":"","show-expand":"","single-expand":"","dense":""},scopedSlots:_vm._u([{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.ddmmyyyy(item.createdDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateStatus(item))+" ")]}},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.domainName(item.domain))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.articles.length)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.toLocaleString('da-DK'))+" ")]}},{key:"item.orderedBy",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(_vm._s(item.orderedBy))])]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.price * item.articles.length).toLocaleString('da-DK'))+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pt-4 pb-4",attrs:{"colspan":"6"}},[_c('v-sheet',{staticClass:"pl-8 pr-8 transparent"},_vm._l((item.articles),function(article,index){return _c('v-row',{key:article.articleId,style:(item.articles.length >= 2 ? 'border-bottom:1px solid #cecece' : '')},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Titel","hide-details":"","outlined":"","dense":""},model:{value:(article.name),callback:function ($$v) {_vm.$set(article, "name", $$v)},expression:"article.name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Ankerværdi","hide-details":"","outlined":"","dense":""},model:{value:(article.anchor),callback:function ($$v) {_vm.$set(article, "anchor", $$v)},expression:"article.anchor"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"URL","hide-details":"","outlined":"","dense":""},model:{value:(article.url),callback:function ($$v) {_vm.$set(article, "url", $$v)},expression:"article.url"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.ddmmyyyy(article.publishDate),"label":"Udgivelsesdato","readonly":"","hide-details":"","outlined":"","dense":""}})],1)],1)}),1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }