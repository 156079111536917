<template>
    <v-sheet
        class="transparent"
        elevation="0"
    >
        <v-data-table
            :items="orders"
            :headers="headers"
            mobile-breakpoint="0"
            sort-by="createdDate"
            sort-desc
            show-expand
            single-expand
            class="transparent"
            dense
        >
            <template v-slot:item.createdDate="{item}">
                {{ddmmyyyy(item.createdDate)}}
            </template>
            <template v-slot:item.status="{item}">
                {{translateStatus(item)}}
            </template>
            <template v-slot:item.domain="{item}">
                {{domainName(item.domain)}}
            </template>

            <template v-slot:item.amount="{item}">
                {{item.articles.length}}
            </template>

            <template v-slot:item.price="{item}">
                {{item.price.toLocaleString('da-DK')}}
            </template>

            <template v-slot:item.orderedBy="{item}">
                <span style="white-space: nowrap;text-overflow: ellipsis;">{{item.orderedBy}}</span>
            </template>

            <template v-slot:item.subtotal="{item}">
                {{(item.price * item.articles.length).toLocaleString('da-DK')}}
            </template>

            <template v-slot:expanded-item="{item}">
                <td colspan="6" class="pt-4 pb-4">
                    <v-sheet class="pl-8 pr-8 transparent">
                        <v-row
                            v-for="(article,index) in item.articles"
                            :key="article.articleId"
                            :style="item.articles.length >= 2 ? 'border-bottom:1px solid #cecece' : ''"
                        >
                            <v-col>
                                <v-text-field
                                    v-model="article.name"
                                    label="Titel"
                                    hide-details
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="article.anchor"
                                    label="Ankerværdi"
                                    hide-details
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="article.url"
                                    label="URL"
                                    hide-details
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    :value="ddmmyyyy(article.publishDate)"
                                    label="Udgivelsesdato"
                                    readonly
                                    hide-details
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </td>
            </template>
        </v-data-table>
    </v-sheet>

</template>

<script>
    import api from "../../services/api";
    import DateTimePicker from "../dateTimePicker/dateTimePicker";

    export default {
        name: "activeOrders",
        components: {DateTimePicker},
        data() {
            return {
                orders: [],
                headers: [
                    {text: 'Bestilt', value: 'createdDate', width: 105},
                    {text: 'Status', value: 'status', width: 100},
                    {text: 'Bestilling', value: 'domain'},
                    {text: 'Bestilt af', value: 'orderedBy', width: 100},
                    {text: 'Artikler', value: 'amount', align: 'center', width: 108},
                    {text: 'Stk. pris', value: 'unitPrice', align: 'right', width: 120},
                    {text: 'Total', value: 'total', align: 'right', width: 140},
                ],
                orderExpandArticleHeaders: [
                    {text: '', value: 'index', width: '20px', sortable: false},
                    {text: 'Ønsket artikelnavn *', value: 'name'},
                    {text: 'Ønsket ankertekst **', value: 'anchor'},
                    {text: 'Linkadresse', value: 'url'},
                    {text: 'Ønsket udgivelsesdato', value: 'publishDate'},
                    {text: '', value: 'remove'},
                ],
                cvr: '',
                statusMessage: {
                    received: 'Modtaget',
                    processing: 'Behandles',
                    completed: 'Færdig',
                }
            }
        },
        methods: {
            getOrders() {
                this.getCvr()
                    .then(() => {
                        api.get('orders/cvr/' + this.$store.state.auth.user.cvr)
                            .then(response => {
                                this.orders = response.data
                            })
                    })
            },
            async getCvr() {
                await api.get('users/' + this.$store.state.auth.user.uid)
                    .then(response => {
                        console.log(response)
                        this.cvr = response.data.cvr
                    })
            },
            domainName(domain) {
                return domain.split(' Info:')[0]
            },
            yyyymmdd(date) {
                let [dd, mm, yyyy] = date.split('-')
                return `${yyyy}-${mm}-${dd}`
            },
            ddmmyyyy(date) {
                let [yyyy, mm, dd] = date.split(' ')[0].split('-')
                return `${dd}-${mm}-${yyyy}`
            },
            translateStatus(item) {
                return this.statusMessage[item.status]
            }

        },
        mounted() {
            this.getOrders()
        }
    }
</script>

<style scoped>

</style>