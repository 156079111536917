import api from "./api";

class uploadService {
    async upload(uploadArea, file, onUploadProgress) {
        return await new Promise((resolve, reject) => {
            let ret
            let formData = new FormData();
            formData.append('file', file)
            api.post('/file-manager/' + uploadArea, formData, {
                headers: {
                    "content-type": "multipart/form-data"
                },
                onUploadProgress
            })
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}

export default new uploadService()