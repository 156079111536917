<template>
    <v-container class="pa-sm-1 pa-md-1 pa-lg-8 pa-xl-8 pt-sm-12 pt-md-12 pt-12">
        <!--div :style="'background-image:url('+background+');background-size: cover;height:320px;'"></div-->
        <v-row>
            <v-col class="hidden-sm-and-down" md="1" lg="2" xl="2"></v-col>
            <v-col>
                <v-sheet
                    :class="
                    avatarSize > 65 ?
                    ' glass--bg  mb-10 pb-4 pt-4 pl-2':
                    ' glass--bg  mb-10 pb-8 pt-8'"
                >
                    <v-list-item>
                        <v-hover v-slot="{ hover }">
                            <v-list-item-avatar tile :size="avatarSize" v-if="avatarSize > 64">
                                <v-img
                                    :src="'https://lb.kompetencekanalen.dk'+user.company.logo"
                                    @load="onAvatarLoad"
                                    style="display:none;"
                                />
                                <div v-if="!avatarLoaded">
                                    <v-skeleton-loader
                                        type="image"
                                        :height="avatarSize"
                                        :width="avatarSize"
                                    />
                                </div>
                                <div v-else>
                                    <v-img
                                        :src="'https://lb.kompetencekanalen.dk'+user.company.logo"
                                        :height="avatarSize"
                                        :width="avatarSize"
                                    />
                                </div>
                                <div
                                    class="d-flex justify-center align-center transition-fast-in-fast-out  white--text"
                                    style="height: 100%;width:100%;position:absolute;bottom:0;transition: 225ms ease;background:rgba(0,0,0,0.25)"
                                    :style="hover ? 'opacity:1':'opacity:0'"
                                >
                                    <company-logo-upload @uploadSuccess="getUser"/>
                                </div>
                            </v-list-item-avatar>
                        </v-hover>
                        <v-list-item-content>
                            <v-card elevation="0" class="transparent">
                                <v-card-text class="text--black lighten-2 pt-1 pb-1">
                                    <v-row>
                                        <v-col cols="12">
                                            <h1 class="font-weight-regular">
                                                {{user.company.name}}
                                            </h1>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="6"
                                            lg="6"
                                            xl="6">
                                            <v-row>
                                                <v-col cols="6" class="pb-0">Hovedkontor</v-col>
                                                <v-col cols="6" class="pb-0 font-weight-bold">{{user.company.city + ', '
                                                    + user.company.country}}
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="6" class="pb-0 pt-1">Kontaktperson</v-col>
                                                <v-col cols="6" class="pb-0 pt-1 font-weight-bold">
                                                    <a :href="'mailto:'+user.mail" style="text-decoration: none;">
                                                        <v-icon small>mdi-email-outline</v-icon>
                                                        {{user.name}}
                                                    </a>
                                                </v-col>

                                            </v-row>

                                            <v-row>
                                                <v-col class="pt-3">
                                                    <span>{{user.company.postCode}} {{user.company.city}}</span>
                                                    <br>
                                                    <span>{{user.company.street}}</span>
                                                    <br>
                                                    <span>DK-{{user.company.cvr}}</span>
                                                </v-col>
                                                <v-col cols="12" class="pb-0 pt-1">
                                                    Hjemmeside<br>
                                                    <span
                                                        class="font-weight-bold">https://www.kompetencekanalen.dk</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="6"
                                            lg="6"
                                            xl="6">
                                            <v-row>
                                                <v-col class="pb-0" cols="6">
                                                    Branche<br>
                                                </v-col>
                                                <v-col class="pb-0" cols="6">
                                                    <span class="font-weight-bold">{{user.company.business}}</span>
                                                </v-col>
                                                <v-col cols="6">
                                                    Type<br>
                                                </v-col>
                                                <v-col cols="6">
                                                    <span class="font-weight-bold">{{user.company.type}}</span>
                                                </v-col>
                                                <v-col class="pb-0" cols="6">
                                                    Grundlagt<br>
                                                </v-col>
                                                <v-col class="pb-0" cols="6">
                                                    <span class="font-weight-bold">{{user.company.founded}}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-list-item-content>
                    </v-list-item>
                </v-sheet>
                <v-row>
                    <v-col cols="12">
                        <v-sheet
                            tile
                            class="mb-8 light--border  glass--bg"
                        >
                            <v-tabs v-model="activeTab">
                                <v-tab>Aktive bestillinger</v-tab>
                                <v-tab>Historik</v-tab>
                                <v-tab>Fakturaer</v-tab>
                            </v-tabs>
                            <v-tabs-items
                                touchless
                                v-model="activeTab"
                            >
                                <v-tab-item class="pb-4 ">
                                    <active-orders/>
                                </v-tab-item>
                                <v-tab-item>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-card-text>Fakturaer</v-card-text>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-row>

                    <v-col cols="4">
                        <v-sheet
                            tile
                            class="pa-8 mb-8 light--border glass--bg"
                        >
                            <v-card-text>
                                <h2 class="mb-8">Strategi</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse lacinia metus
                                    ultricies dolor gravida maximus. Aliquam semper, metus nec ultrices interdum, eros
                                    nunc mattis metus, sed sollicitudin nunc magna eget lectus.</p>
                            </v-card-text>
                        </v-sheet>
                        <v-sheet
                            tile
                            class="pa-8 mb-8 light--border glass--bg"
                        >
                            <v-card-text>
                                <h2 class="mb-8">Mere</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse lacinia metus
                                    ultricies dolor gravida maximus. Aliquam semper, metus nec ultrices interdum, eros
                                    nunc mattis metus, sed sollicitudin nunc magna eget lectus. Fusce eget diam urna.
                                    Praesent vitae ligula ac enim consequat faucibus id ut metus. Nunc turpis ex,
                                    lacinia ut lacus at, convallis congue sapien. Aenean sit amet nibh magna. In sit
                                    amet magna ac turpis imperdiet faucibus. Donec eu ligula felis.</p>
                            </v-card-text>
                        </v-sheet>
                    </v-col>
                    <v-col cols="8">
                        <v-sheet
                            tile
                            height="400"
                            class="mb-8 light--border glass--bg"
                        >
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="hidden-sm-and-down" md="1" lg="2" xl="2"></v-col>
        </v-row>
    </v-container>
</template>

<script>
    import api from "../services/api";
    import CommitList from "../components/admin/commitList";
    import Cart from "./Cart";
    import ActiveOrders from "../components/orders/activeOrders";
    import CompanyLogoUpload from "../components/fileManagement/companyLogoUpload";

    export default {
        name: "Profile",
        components: {CompanyLogoUpload, ActiveOrders, Cart, CommitList},
        data() {
            return {
                editing: false,
                user: {
                    company: {}
                },
                background: '',
                activeTab: 0,
                avatarLoaded: false,
            }
        },
        methods: {
            getUser() {
                api.get('users/' + this.$store.state.auth.user.uid)
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(err => {
                        this.$store.dispatch('toasts/toastError', err.response.message)
                    })
            },
            updateUserInfo() {
                api.put('users/' + this.$store.state.auth.user.uid, this.user)
                    .then(response => {
                        this.$store.dispatch('auth/refreshUserInfo')
                        this.$store.dispatch('toasts/addToast', {
                            text: 'Brugerinformation opdateret',
                            type: 'success',
                            icon: 'mdi-check'
                        })
                    })
                    .catch(err => {
                        this.$store.dispatch('toasts/addToast', {
                            text: 'Der skete et fejl',
                            type: 'warning',
                            icon: 'mdi-exclamation'
                        })
                    })
            },
            randomBackground() {
                let backgrounds = [
                    "/img/59-1920x420.webp",
                    "/img/123-1920x420.webp",
                    "/img/186-1920x420.webp",
                    "/img/221-1920x420.webp",
                    "/img/259-1920x420.webp",
                    "/img/658-1920x420.webp",
                    "/img/823-1920x420.webp",
                    "/img/1070-1920x420.webp",
                ];
                return backgrounds[Math.floor(Math.random() * backgrounds.length)];
            },
            onAvatarLoad() {
                this.avatarLoaded = true
            }
        },
        mounted() {
            this.$store.dispatch('auth/refreshUserInfo')
            this.getUser()
            this.background = this.randomBackground()
        },
        computed: {
            initials: function () {
                const name = this.$store.state.auth.user.name
                const parts = name.split(' ')
                return parts[0].slice(0, 1) + parts[parts.length - 1].slice(0, 1)
            },
            avatarSize: function () {
                let size = 256
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                    case 'sm':
                        size = 32
                        break;
                    case 'md':
                        size = 192
                        break;
                    case 'lg':
                    case 'xl':
                        size = 256
                        break;

                }
                return size
            }
        }
    }
</script>

<style>
</style>