<template>
    <v-container class="pa-sm-1 pa-md-1 pa-lg-8 pa-xl-8 pt-sm-8 pt-md-8 pt-12">
        <v-row>
            <v-col v-if="!orderConfirmed" sm="12" md="10" lg="8" xl="8" class="mx-auto">
                <v-row>
                    <v-col>
                        <v-card
                            :loading="orderProcessing"
                            class="glass--bg"
                        >
                            <v-toolbar dense elevation="0" color="transparent" dark>
                                <v-toolbar-title>
                                    <v-btn icon @click="$router.go(-1)">
                                        <v-icon>mdi-arrow-left</v-icon>
                                    </v-btn>
                                    Bestillinger
                                </v-toolbar-title>
                                <v-spacer/>
                                <v-btn text small @click="$store.dispatch('cartModule/emptyCart')">
                                    <v-icon left>mdi-delete-empty</v-icon>
                                    Tøm kurv
                                </v-btn>
                            </v-toolbar>

                            <v-data-table
                                :items="$store.state.cartModule.orders"
                                :headers="headers"
                                mobile-breakpoint="0"
                                hide-default-footer
                                disable-sort
                                show-expand
                                single-expand
                                class="transparent"
                            >
                                <template v-slot:expanded-item="{item}">
                                    <td colspan="6" class="pt-4 pb-4">
                                        <v-sheet class="pl-8 pr-8">
                                            <v-row
                                                v-for="(article,index) in item.articles"
                                                :key="article.articleId"
                                                :style="item.articles.length >= 2 ? 'border-bottom:1px solid #cecece' : ''"
                                            >
                                                <v-col>
                                                    <v-text-field
                                                        v-model="article.name"
                                                        label="Titel"
                                                        hide-details
                                                        outlined
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        v-model="article.anchor"
                                                        label="Ankerværdi"
                                                        hide-details
                                                        outlined
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        v-model="article.url"
                                                        label="URL"
                                                        hide-details
                                                        outlined
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        :value="new Date().daysFromNow(14)"
                                                        v-model="article.publishDate"
                                                        label="Udgivelsesdato"
                                                        readonly
                                                        hide-details
                                                        outlined
                                                        dense
                                                    >
                                                        <template v-slot:prepend-inner>
                                                            <date-time-picker
                                                                :dateOnly="true"
                                                                :min="new Date().daysFromNow(14)"
                                                                :preSelected="yyyymmdd(article.publishDate)"
                                                                @chosenDate="article.publishDate = $event"
                                                            />
                                                        </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col class="flex-grow-0 mt-3 mr-4">
                                                    <v-icon
                                                        @click="$store.dispatch('cartModule/removeArticleFromCurrentOrder',item.orderId,index)">
                                                        mdi-trash-can
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </td>
                                </template>

                                <template v-slot:item.domain="{item}">
                                    <v-icon left>mdi-note-multiple-outline</v-icon>
                                    {{item.domain}}
                                </template>

                                <template v-slot:item.amount="{item}">
                                    {{item.articles.length}}
                                </template>

                                <template v-slot:item.price="{item}">{{item.price.toLocaleString('da-DK')}}</template>

                                <template v-slot:item.subtotal="{item}">
                                    {{(item.price * item.articles.length).toLocaleString('da-DK')}}

                                </template>

                                <template v-slot:body.append="{item}">
                                    <tr>
                                        <td
                                            v-for="(header,i) in headers"
                                            :key="i"
                                            class="font-weight-bold"
                                            :class="i === headers.length - 1 ? 'text-right':''"
                                        >
                                            <span v-if="i === 0">TOTAL</span>
                                            <span v-if="i === headers.length - 1" class="">{{cartTotal.toLocaleString('da-DK')}} DKK</span>
                                        </td>
                                        <td></td>
                                    </tr>
                                </template>


                                <template v-slot:item.delete="{item}">
                                    <v-btn
                                        icon
                                        small
                                        @click="$store.dispatch('cartModule/removeFromCart',item.id)"
                                    >
                                        <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-sheet
                            class=" glass--bg d-flex pa-4 pb-0 mt-8">
                            <v-checkbox v-model="accept" class="mt-0">
                                <template v-slot:label>
                                    <div @click.stop="" class=" text--primary">
                                        Acceptér
                                        <a
                                            href="#"
                                            @click.prevent="terms = true"
                                            class="light-blue--text"
                                        >handelsbetingelser</a>
                                    </div>
                                </template>
                            </v-checkbox>
                            <v-dialog
                                v-model="terms"
                                width="70%"
                            >
                                <v-card>
                                    <v-card-title class="text-h6">
                                        Terms
                                    </v-card-title>
                                    <v-card-text>
                                        {{ termContent }}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="purple"
                                            @click="terms = false"
                                        >
                                            Ok
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-spacer/>
                            <v-btn color="primary" :disabled="!accept ||  $store.state.cartModule.orders.length === 0 "
                                   @click="confirmOrder()">Bestil
                            </v-btn>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-divider class="mt-2 mb-5"/>
                <p class="caption">* Visse sider tillader ikke man forfatter eget artikelnavn.
                    <br>
                    ** I visse tilfælde kan det have uønsket (negativ) effekt at bruge fokusord som ankertekster. Vi vil
                    derfor tage kontakt, hvis vi mener ankertekster bør tilrettes ift. linkprofilen for din hjemmeside.
                </p>
            </v-col>

            <v-col sm="12" md="10" lg="8" xl="8" class="mx-auto" v-if="orderConfirmed">
                <v-card class="glass--bg">
                    <v-card-text>
                        <v-list color="transparent">
                            <v-list-item>
                                <v-list-item-content class="text-center justify-center">
                                    <v-list-item-title>
                                        <v-list-item-avatar color="green">
                                            <v-icon>mdi-check</v-icon>
                                        </v-list-item-avatar>
                                        Vi har modtaget din ordre
                                    </v-list-item-title>
                                    <p>Vi har sendt modtagelsesbekræftelse på din angivede kontaktmail:<br> {{$store.state.auth.user.contactMail}}<p/>
                                    <br/>
                                    <router-link class="white--text "link :to="{name:'Din side'}" >Din side</router-link>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import DateTimePicker from "../components/dateTimePicker/dateTimePicker";

    Date.prototype.daysFromNow = function (days) {
        let fn = new Date();
        fn.setDate(fn.getDate() + days);
        let month = (fn.getMonth() + 1).toString().length === 1 ? '0' + (fn.getMonth() + 1) : (fn.getMonth() + 1)
        let day = fn.getDate().toString().length === 1 ? '0' + fn.getDate() : fn.getDate()
        return fn.getFullYear() + '-' + month + '-' + day
    }
    export default {
        name: "cart",
        components: {DateTimePicker},
        data() {
            return {
                headers: [
                    {text: 'Bestilling', value: 'domain'},
                    {text: 'Artikler', value: 'amount', align: 'center', width: 108},
                    {text: 'Stk. pris', value: 'price', align: 'right', width: 120},
                    {text: 'Subtotal', value: 'subtotal', align: 'right', width: 140},
                    {text: '', value: 'delete', width: 40},
                ],
                orderExpandArticleHeaders: [
                    {text: '', value: 'index', width: '20px', sortable: false},
                    {text: 'Ønsket artikelnavn *', value: 'name'},
                    {text: 'Ønsket ankertekst **', value: 'anchor'},
                    {text: 'Linkadresse', value: 'url'},
                    {text: 'Ønsket udgivelsesdato', value: 'publishDate'},
                    {text: '', value: 'remove'},
                ],
                accept: false,
                terms: false,
                conditions: false,
                termContent: 'Bla bla handelsbetingelser.',
                orderConfirmed: false,
                orderProcessing: false,
            }
        },
        methods: {
            removeArticleFromCurrentOrder(orderId, articleId) {
                this.$store.state.cartModule.orders[orderId].articles.split(articleId, 1)
            },
            yyyymmdd(date) {
                let [dd, mm, yyyy] = date.split('-')
                return `${yyyy}-${mm}-${dd}`
            },
            confirmOrder() {
                this.orderProcessing = true
                this.$store.dispatch('cartModule/confirmOrder')
                    .then(response => {
                        this.orderProcessing = false
                        this.orderConfirmed = true
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        computed: {
            cartTotal: function () {
                let total = 0
                this.$store.state.cartModule.orders.forEach((obj, i) => {
                    total = total + (obj.price * obj.articles.length)
                })
                return total
            }
            ,
        }
    }
</script>

<style>
    .article--counter--field input {
        text-align: center !important;
    }
</style>